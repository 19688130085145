import { PageNotFound, ProtectedRoute } from "@sk/uis";
import React from "react";
import { createHashRouter, RouterProvider } from "react-router-dom";
import CustomerList from "./pages/customer/list/CustomerList";
import ManageCustomer from "./pages/customer/manage/ManageCustomer";
import Layout from "./pages/Layout";
import FranchiseList from "./pages/franchises/list/FranchiseList";
import CustomerView from "./pages/customer/view/CustomerView";
import ClubDetail from "./pages/customer/club/ClubDetail";

const routes = createHashRouter([
  {
    path: "/",
    errorElement: <PageNotFound />,
    children: [
      {
        path: "users",
        element: <Layout />,
        children: [
          {
            path: "customer/manage",
            element: (
              <ProtectedRoute>
                <ManageCustomer />
              </ProtectedRoute>
            ),
          },
          {
            path: "customer/list",
            element: (
              <ProtectedRoute>
                <CustomerList />
              </ProtectedRoute>
            ),
          },
          {
            path: "franchises/list",
            element: (
              <ProtectedRoute>
                <FranchiseList />
              </ProtectedRoute>
            ),
          },
          {
            path: "customer/view",
            element: (
              <ProtectedRoute>
                <CustomerView />
              </ProtectedRoute>
            ),
          },
          {
            path: "customer/club/view",
            element: (
              <ProtectedRoute>
                <ClubDetail />
              </ProtectedRoute>
            ),
          },
        ],
      },
    ],
  },
]);

const Router = () => {
  return <RouterProvider router={routes} />;
};

export default Router;
