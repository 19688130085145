import { LoyaltyPointService } from "@sk/services";
import {
  DateFormatter,
  DateInput,
  NoDataFound,
  SelectInput,
  Spinner,
  TableHeader,
  TableSkeletonLoader,
} from "@sk/uis";
import { set } from "date-fns";
import { useCallback, useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";

const dateConfig = {
  mode: "range",
  maxDate: new Date(),
};

const containerStyle = {
  height: "600px", // Set the desired height for the container
  overflowY: "auto", // Enable vertical scrolling
};

const tableStyle = {
  minWidth: "700px", // Updated width
};

const styles = {
  credit: { background: "#efffef" },
  debit: { background: "rgb(255 244 244)" },
  noData: { textAlign: "center" },
};

const defaultPaginationConfig = {
  totalRecords: 0,
  rowsPerPage: 15,
  activePage: 1,
  startSlNo: 1,
  endSlNo: 15,
};

const defaultFilterData = {
  // Define any default filter data if necessary
};

const transTypeOptions = [
  { value: "All", label: "All" },
  { value: "Credit", label: "Only Credit" },
  { value: "Debit", label: "Only Debit" },
];

const defaultFormData = {
  dateRange: [], // For date range
  transactionType: "All", // For select dropdown
};

const headers = [
  { label: "Sl No", width: "8%" },
  { label: "Transaction", width: "15%" },
  { label: "Remarks", width: "40%" },
  { label: "Credit", isCentered: true },
  { label: "Debit", isCentered: true },
  { label: "Date", width: "20%" },
];

const getData = async (params) => {
  const r = await LoyaltyPointService.getStatement(params); // Adjust API call
  return { data: Array.isArray(r.resp) ? r.resp : [] };
};

const prepareFilterParams = (filter, pagination) => {
  let p = {
    page: pagination.activePage,
    count: pagination.rowsPerPage,
    filter: {
      ownerId: filter.cid,
      ownerType: "customer",
    },
    sort: "-createdAt",
  };

  if (filter?.dateRange?.length > 0) {
    p.filter.processedAt = {
      $gte: set(new Date(filter.dateRange[0]), {
        hours: 0,
        minutes: 0,
        seconds: 0,
      }),
      $lte: set(new Date(filter.dateRange[1]), {
        hours: 23,
        minutes: 59,
        seconds: 59,
      }),
    };
  }

  if (filter.transactionType == "Credit") {
    p.filter.payoutType = {
      $in: ["Credit", "Reward"],
    };
  }

  if (filter.transactionType == "Debit") {
    p.filter.payoutType = "Debit";
  }

  return p;
};

const CustKcStatement = ({ cid = "" }) => {
  const { control, register, getValues } = useForm({
    defaultValues: defaultFormData,
  });

  const [data, setData] = useState([]);

  const [loadingMore, setLoadingMore] = useState(false); // State for loading more data

  const [showLoadMore, setShowLoadMore] = useState(true); // State to show/hide Load More button

  const [loadingData, setLoadingData] = useState(true);

  const filterDataRef = useRef({ ...defaultFilterData });

  const paginationRef = useRef({ ...defaultPaginationConfig });

  const applyFilter = useCallback(async () => {
    paginationRef.current = { ...defaultPaginationConfig };

    filterDataRef.current = {
      ...filterDataRef.current,
      ...getValues(),
    };

    loadList();
  }, [getValues, loadList]);

  const init = useCallback(async () => {
    applyFilter();
  }, [applyFilter]);

  useEffect(() => {
    filterDataRef.current = { cid: cid };

    init();
  }, [cid, init]);

  const loadList = useCallback(async () => {
    setLoadingData(true);

    setData([]);

    const r = await getData(
      prepareFilterParams(filterDataRef.current, paginationRef.current)
    );

    const d = r.data || [];

    setData(d);

    // hide loader more button if has lesser data
    setShowLoadMore(d.length >= paginationRef.current.rowsPerPage);

    setLoadingData(false);
  }, []);

  const loadMore = async () => {
    setLoadingMore(true);

    paginationRef.current.activePage += 1;

    const r = await getData(
      prepareFilterParams(filterDataRef.current, paginationRef.current)
    );

    const newData = r.data || [];

    setShowLoadMore(newData.length > 0);

    setData((prevData) => [...prevData, ...newData]); // Concatenate old and new data

    setLoadingMore(false);
  };

  const onDateChange = (chngFn) => (val) => {
    chngFn(val);
    applyFilter();
  };

  const onTypeChange = () => {
    applyFilter();
  };

  return (
    <>
      <div className="row mb-3">
        <div className="col-6">
          <Controller
            name="dateRange"
            control={control}
            render={({ field }) => (
              <DateInput
                label="Date Range"
                onChange={field.onChange}
                value={field.value}
                callback={onDateChange(field.onChange)}
                config={dateConfig}
                gap={0}
                template={2}
              />
            )}
          />
        </div>
        <div className="col-6">
          <SelectInput
            label="Transaction Type"
            options={transTypeOptions}
            register={register}
            name="transactionType"
            callback={onTypeChange}
            gap={0}
          />
        </div>
      </div>
      <div
        className="tbl-scroll-container custom-scrollbar"
        style={containerStyle}
      >
        <table className="table table-sm" style={tableStyle}>
          <TableHeader data={headers} noBg={true} isSticky={true} />
          <tbody className="fs-val-md">
            {!loadingData && !data.length ? (
              <tr>
                <td colSpan={headers.length} style={styles.noData}>
                  <NoDataFound>No Data found</NoDataFound>
                </td>
              </tr>
            ) : null}

            {loadingData ? (
              <TableSkeletonLoader
                cols={headers.length}
                rows={10}
                height={40}
              />
            ) : null}

            {data.map((x, index) => (
              <tr key={x._id} className="fs-val-md">
                <td>{index + 1}</td>
                <td>{x._transactionTypeLabel}</td>
                <td
                  className="break-words text-wrap"
                  style={{
                    width: "40%",
                  }}
                >
                  {x._remarks}
                </td>
                <td className="text-success text-center" style={styles.credit}>
                  {x._type === "Earned" ? x.points : ""}
                </td>
                <td className="text-danger text-center" style={styles.debit}>
                  {x._type !== "Earned" ? x.points : ""}
                </td>
                <td>
                  <DateFormatter date={x.processedAt} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="text-center mt-3">
        {showLoadMore && !loadingMore && (
          <button className="btn btn-light btn-sm" onClick={loadMore}>
            Load More
          </button>
        )}
        {loadingMore && <Spinner isSmall title="Loading more..." />}
      </div>
    </>
  );
};

export default CustKcStatement;
