import { NoDataFound, TableHeader, Toaster } from "@sk/uis";

const headers = [
  {
    label: "Sl No",
  },
  {
    label: "Is Default",
  },
  {
    label: "Name",
  },
  {
    label: "Address",
  },
  {
    label: "Pincode",
  },
  {
    label: "State",
  },
  {
    label: "District",
  },
];

const CustShippingAddress = ({ addresses = [] }) => {
  const viewOnMap = (e) => {
    const coords = e?.geolocation?.coordinates || [];
    if (coords.length == 2) {
      window.open(
        "https://maps.google.com/?q=" + coords[0] + "," + coords[1],
        "_blank"
      );
    } else {
      Toaster.error("Location details not found");
    }
  };

  return (
    <>
      <table className="table table-sm table-bordered">
        <TableHeader data={headers} />
        <tbody className="fs-val-md">
          {!addresses?.length ? (
            <>
              <tr>
                <td colSpan={headers.length}>
                  <NoDataFound>No address to display</NoDataFound>
                </td>
              </tr>
            </>
          ) : null}
          {(addresses || []).map((e, i) => (
            <tr key={i}>
              <td>{i + 1}</td>
              <td>{e.isDefault ? "Yes" : "No"}</td>
              <td>{e.name}</td>
              <td>
                <div>{e.address?.full_address}</div>
                <div>
                  <span className="text-muted">Landmark: </span>
                  {e.address.landmark}
                </div>
                <div>
                  <button
                    className="btn btn-link btn-sm fs-val-xs p-0"
                    onClick={() => viewOnMap(e)}
                  >
                    <i className="bi bi-geo-alt me-1"></i>
                    View on Map
                  </button>
                </div>
              </td>
              <td>{e.pincode}</td>
              <td>{e.state}</td>
              <td>{e.district}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default CustShippingAddress;
