import { useFetchUrlQueryString } from "@sk/hooks";
import {
  AuthService,
  CoinsDashService,
  CommonService,
  CustomerService,
  FranchiseService,
  LoyaltyPointService,
  NavService,
} from "@sk/services";
import {
  ImgRender,
  Rbac,
  Tabs,
  KeyVal,
  DateFormatter,
  AppCard,
  PageInfo,
  Toaster,
  BtnLink,
  BusyLoader,
} from "@sk/uis";
import { get } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import {
  CustomerCallLogs,
  CustomerMsgTemplateModal,
  CustomerUpdateModal,
  StoresModal,
} from "@sk/features";
import CustBasicDetails from "./components/CustBasicDetails";
import CustKcStatement from "./components/CustKcStatement";
import CustOrders from "./components/CustOrders";
import CustShippingAddress from "./components/CustShippingAddress";
import CustTopPurchases from "./components/CustTopPurchases";

const tabData = [
  {
    tabName: "Orders",
    key: "orders",
  },
  {
    tabName: "Coins Statement",
    key: "statement",
  },
  {
    tabName: "Top Purchases",
    key: "topPurchases",
  },
  // {
  //   tabName: "Monthly Summary",
  //   key: "monthlySummary",
  // },
];

const avatarStyle = {
  width: "60px",
  height: "60px",
  border: "2px solid #eee",
};

const customerTabs = [
  {
    tabName: "Details",
    key: "basicDetails",
  },
  {
    tabName: "Address",
    key: "addressDetails",
  },
  // {
  //   tabName: "Device Details",
  //   key: "moreDetails",
  // },
];

const rbac = {
  callToCustomer: ["CallToCustomer"],
  messageToCustomer: ["MessageToCustomer"],
  whatsAppToCustomer: ["WhatsappToCustomer"],
  featureAccess: ["FeatureAccess", "CustomerClubRequestSuspend"],
  editCustomer: ["EditCustomer"],
  changeCustomerLinkedStore: ["ChangeCustomerLinkedStore"],
};

const compactInfoCardStyle = {
  flex: 1,
  padding: "0.75rem",
  borderRadius: "8px",
  backgroundColor: "#fff",
  border: "1px solid #e9ecef",
  minWidth: "150px",
};

const breadcrumbs = [
  {
    name: "Home",
    link: "/",
  },
  {
    name: "Customer Details",
    link: "",
  },
];

const CustomerView = () => {
  const router = useNavigate();

  const [searchParams] = useSearchParams();
  const query = useFetchUrlQueryString(searchParams);
  const cid = query.id;

  const [activeTab, setActiveTab] = useState(tabData[0]);
  const [details, setDetails] = useState({ loading: true, data: {} });
  const [reward, setReward] = useState({ loading: true, value: 0 });
  const [redeemed, setRedeemed] = useState({ loading: true, value: 0 });
  const [balance, setBalance] = useState({ loading: true, value: 0 });
  const [expired, setExpired] = useState({ loading: true, value: 0 });

  const [callModal, setCallModal] = useState({
    show: false,
    customer: null,
  });

  const [msgTemplateModal, setMsgTemplateModal] = useState({
    show: false,
    customer: null,
    type: "",
  });

  const [customerTab, setCustomerTab] = useState(customerTabs[0]);

  const [updateModal, setUpdateModal] = useState({
    show: false,
    customerId: null,
  });

  const [showStoreModal, setShowStoreModal] = useState(false);

  const [busyloader, setBusyloader] = useState({ show: false });

  useEffect(() => {
    if (cid) {
      setActiveTab(tabData[0]);
      setCustomerTab(customerTabs[0]);
      init(cid);
    }
  }, [cid]);

  const init = useCallback((id) => {
    loadDetails(id);
    loadReward(id);
    loadRedeem(id);
    loadBalance(id);
    loadExpired(id);
  }, []);

  const loadDetails = async (id) => {
    setDetails({ loading: true, data: {} });
    const r = await CustomerService.getDetail(id, {
      select:
        "fName,lName,age,email,gender,dob,createdAt,clubName,referralInfo,image,franchise,mobile,maritalStatus,eductioanQualification,kycDetails,generalInformation,monthlyShoppingExpenses,numberOfFamilyMembers,ipAccessDetail,lastlogedIn,shippingAddress,customerSuspended",
    });

    if (r.resp?.franchise) {
      const r2 = await FranchiseService.getFranchise(r.resp?.franchise, {
        select: "name",
      });
      r.resp.franchiseName = r2.resp?.name || "";
    }

    if (r.resp?.referralInfo?.referrerInfo?.id) {
      const rcid = r.resp?.referralInfo?.referrerInfo?.id;
      const r2 = await CustomerService.getDetail(rcid, {
        select: "clubName",
      });
      r.resp._parentClubId = rcid;
      r.resp._parentClub = r2.resp?.clubName || "";
    }

    const ipAccessDetail = (r.resp?.ipAccessDetail || []).find(
      (i) => i.isActive === true
    );

    if (ipAccessDetail) {
      ipAccessDetail.platform = CommonService.detectPlatform(
        ipAccessDetail.userAgent
      );
    }

    setDetails({
      loading: false,
      data: {
        ...r.resp,
        ipAccessDetail,
      },
    });
  };

  const loadReward = async (id) => {
    setReward({ loading: true, value: 0 });
    const r = await CoinsDashService.getCoinsRewardedCount({
      filter: { ownerId: id },
    });
    setReward({
      loading: false,
      value: get(r.resp, "[0].totalCoinsRewarded", 0),
    });
  };

  const loadRedeem = async (id) => {
    setRedeemed({ loading: true, value: 0 });
    const r = await CoinsDashService.getCoinsRedeemedCount({
      filter: { ownerId: id },
    });
    setRedeemed({
      loading: false,
      value: get(r.resp, "[0].totalCoinsRedeem", 0),
    });
  };

  const loadBalance = async (id) => {
    setBalance({ loading: true, value: 0 });
    const r = await CommonService.getKcBalance(id, "customer");
    setBalance({
      loading: false,
      value: get(r.resp, "useablePoints", 0),
    });
  };

  const loadExpired = async (id) => {
    setExpired({ loading: true, value: 0 });
    const r = await LoyaltyPointService.getExpiredPoints(id, "customer");
    setExpired({
      loading: false,
      value: r.points,
    });
  };

  const onTabChange = (tab) => {
    setActiveTab(tab.value);
  };

  const viewClub = () => {
    NavService.openInNewTab(`/users/customer/club/view`, {
      id: details.data._id,
    });
  };

  const viewParentClub = () => {
    NavService.openInNewTab(`/users/customer/club/view`, {
      id: details.data._parentClubId,
    });
  };

  const onCustomerTabChange = (tab) => {
    setCustomerTab(tab.value);
  };

  const handleCall = async () => {
    const emp = AuthService.getLoggedInEmp();
    if (!emp?.agentId) {
      Toaster.error("For you agent is not assigned");
      return;
    }

    setCallModal({
      show: true,
      customer: {
        id: cid,
        name: `${details.data?.fName} ${details.data?.lName}`,
        mobile: details.data?.mobile,
        image: details.data?.image,
        createdAt: details.data?.createdAt,
      },
    });
  };

  const handleSendSMS = async () => {
    setMsgTemplateModal({
      show: true,
      type: "SMS",
      customer: {
        id: cid,
        name: `${details.data?.fName} ${details.data?.lName}`,
        mobile: details.data?.mobile,
        image: details.data?.image,
        createdAt: details.data?.createdAt,
      },
    });
  };

  const handleSendWhatsApp = async () => {
    setMsgTemplateModal({
      show: true,
      type: "WhatsApp",
      customer: {
        id: cid,
        name: `${details.data?.fName} ${details.data?.lName}`,
        mobile: details.data?.mobile,
        image: details.data?.image,
        createdAt: details.data?.createdAt,
      },
    });
  };

  const onCallLogsCb = () => {
    setCallModal({ show: false, customer: null });
  };

  const onMsgTemplateCb = () => {
    setMsgTemplateModal({ show: false, customer: null });
  };

  const onUpdateModalCb = ({ updated }) => {
    setUpdateModal({ show: false, customerId: null });
    if (updated) {
      init(cid);
    }
  };

  const storeModalCallback = async (e) => {
    if (e?.action === "selectedStore") {
      setBusyloader({ show: true });

      try {
        const payload = { franchise: e.store[0]._id };
        const response = await CustomerService.updateCustomer(cid, payload);

        if (response.statusCode === 200) {
          Toaster.success("Store updated successfully");
          init(cid);
        } else {
          Toaster.error("Failed to update store: " + response.resp?.message);
        }
      } catch (error) {
        console.error("Error updating store:", error);
        Toaster.error("An error occurred while updating the store.");
      } finally {
        setBusyloader({ show: false });
      }
    }
    setShowStoreModal(false);
  };

  return (
    <>
      <PageInfo
        title="Customer Details"
        breadcrumbs={breadcrumbs}
        navigate={router}
      />

      <AppCard>
        <div className="row">
          <div className="col-12 mb-4">
            <div className="d-flex">
              <div
                className="rounded-circle overflow-hidden d-flex align-items-center justify-content-center flex-shrink-0"
                style={avatarStyle}
              >
                {details.data?.image ? (
                  <ImgRender assetId={details.data.image} className="h-100" />
                ) : (
                  <i
                    className="bi bi-person-circle text-secondary"
                    style={{ fontSize: "40px" }}
                  ></i>
                )}
              </div>
              <div className="ms-3 flex-grow-1">
                <div className="d-flex justify-content-between">
                  <div>
                    <h4 className="mb-1 fw-bold">
                      {details.data?.fName} {details.data?.lName}
                    </h4>
                    <div className="d-flex align-items-center gap-3 text-muted">
                      <div className="fs-val-md">
                        <i className="bi bi-telephone-fill me-1"></i>
                        {details.data?.mobile || "No contact number"}
                      </div>
                      <div className="fs-val-md">
                        <i className="bi bi-calendar-fill me-1"></i>
                        Registered:
                        <DateFormatter
                          date={details.data?.createdAt}
                          format={"dd MMM yyyy"}
                        />
                      </div>

                      <Rbac roles={rbac.editCustomer}>
                        <>
                          {/* edit customer */}
                          <div className="fs-val-md">
                            <button
                              className="btn btn-link fs-val-md text-dark text-start p-0"
                              onClick={() =>
                                setUpdateModal({
                                  show: true,
                                  customerId: cid,
                                })
                              }
                            >
                              <i className="bi bi-pencil-fill me-1"></i>
                              Update KYC Details
                            </button>
                          </div>
                        </>
                      </Rbac>
                    </div>
                  </div>

                  {/* Communication Buttons - Dark Theme */}
                  {details.data?.mobile && (
                    <div className="d-flex align-items-center gap-2">
                      <Rbac roles={rbac.callToCustomer}>
                        <button
                          className="btn rounded-circle p-2 d-flex align-items-center justify-content-center shadow-sm"
                          style={{
                            width: "45px",
                            height: "45px",
                            backgroundColor: "#0d6efd", // Primary blue
                            transition: "all 0.2s ease",
                            border: "none",
                          }}
                          onClick={handleCall}
                          title="Call"
                        >
                          <i className="bi bi-telephone-fill fs-5 text-white"></i>
                        </button>
                      </Rbac>

                      <Rbac roles={rbac.whatsAppToCustomer}>
                        <button
                          className="btn rounded-circle p-2 d-flex align-items-center justify-content-center shadow-sm"
                          style={{
                            width: "45px",
                            height: "45px",
                            backgroundColor: "#25D366", // WhatsApp green
                            transition: "all 0.2s ease",
                            border: "none",
                          }}
                          onClick={handleSendWhatsApp}
                          title="WhatsApp"
                        >
                          <i className="bi bi-whatsapp fs-5 text-white"></i>
                        </button>
                      </Rbac>

                      <Rbac roles={rbac.messageToCustomer}>
                        <button
                          className="btn rounded-circle p-2 d-flex align-items-center justify-content-center shadow-sm"
                          style={{
                            width: "45px",
                            height: "45px",
                            backgroundColor: "#0dcaf0", // Info blue
                            transition: "all 0.2s ease",
                            border: "none",
                          }}
                          onClick={handleSendSMS}
                          title="SMS"
                        >
                          <i className="bi bi-chat-dots-fill fs-5 text-white"></i>
                        </button>
                      </Rbac>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 mb-3">
            <div className="d-flex gap-2">
              <div style={compactInfoCardStyle}>
                <div className="d-flex align-items-center justify-content-between mb-1">
                  <div>
                    <i className="bi bi-shop text-primary fs-6 me-2"></i>
                    <span className="text-muted small">Registration Store</span>
                  </div>
                  {!details.data?.franchise ? (
                    <Rbac roles={rbac.changeCustomerLinkedStore}>
                      <div>
                        <BtnLink
                          className="fs-val-xs"
                          color="text-secondary"
                          onClick={() => setShowStoreModal(true)}
                        >
                          <i className="bi bi-pencil-fill me-1"></i>
                          Change Store
                        </BtnLink>
                      </div>
                    </Rbac>
                  ) : null}
                </div>
                <button
                  className="btn btn-link p-0 text-dark fw-medium fs-val-md"
                  onClick={() => {
                    NavService.openInNewTab(
                      `/pos/sales-analytics?fid=${details.data?.franchise}`
                    );
                  }}
                >
                  {details.data?.franchiseName || "--"}
                </button>
              </div>

              <div style={compactInfoCardStyle}>
                <div className="d-flex align-items-center mb-1">
                  <i className="bi bi-person-badge text-success fs-6 me-2"></i>
                  <span className="text-muted small">Own Club</span>
                </div>
                {details.data?.clubName ? (
                  <button
                    className="btn btn-link p-0 text-dark fw-medium fs-val-md"
                    onClick={viewClub}
                  >
                    {details.data?.clubName}
                  </button>
                ) : (
                  <span className="text-dark fw-medium small">--</span>
                )}
              </div>

              <div style={compactInfoCardStyle}>
                <div className="d-flex align-items-center mb-1">
                  <i className="bi bi-people text-info fs-6 me-2"></i>
                  <span className="text-muted small">Part of Club</span>
                </div>
                {details.data?._parentClub ? (
                  <button
                    className="btn btn-link p-0 text-dark fw-medium fs-val-md"
                    onClick={viewParentClub}
                  >
                    {details.data?._parentClub}
                  </button>
                ) : (
                  <span className="text-dark fw-medium small">--</span>
                )}
              </div>
            </div>
          </div>

          <div className="col-12">
            <div className="row g-3">
              <div className="col-3">
                <div className="p-3 rounded-3 bg-primary bg-opacity-10 border border-primary h-100">
                  <div className="d-flex align-items-center">
                    <div className="me-3">
                      <i className="bi bi-coin fs-3 text-primary"></i>
                    </div>
                    <div>
                      <div className="text-muted small">Coins Earned</div>
                      <div className="fs-5 fw-bold text-primary">
                        {reward.value}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-3">
                <div className="p-3 rounded-3 bg-danger bg-opacity-10 border border-danger h-100">
                  <div className="d-flex align-items-center">
                    <div className="me-3">
                      <i className="bi bi-cart-check fs-3 text-danger"></i>
                    </div>
                    <div>
                      <div className="text-muted small">Coins Redeemed</div>
                      <div className="fs-5 fw-bold text-danger">
                        {redeemed.value}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-3">
                <div className="p-3 rounded-3 bg-success bg-opacity-10 border border-success h-100">
                  <div className="d-flex align-items-center">
                    <div className="me-3">
                      <i className="bi bi-wallet2 fs-3 text-success"></i>
                    </div>
                    <div>
                      <div className="text-muted small">Coins Available</div>
                      <div className="fs-5 fw-bold text-success">
                        {balance.value}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-3">
                <div className="p-3 rounded-3 bg-warning bg-opacity-10 border border-warning h-100">
                  <div className="d-flex align-items-center">
                    <div className="me-3">
                      <i className="bi bi-hourglass-split fs-3 text-warning"></i>
                    </div>
                    <div>
                      <div className="text-muted small">Coins Expired</div>
                      <div className="fs-5 fw-bold text-warning">
                        {expired.value}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AppCard>

      <AppCard>
        <div className="border-bottom mb-3">
          <Tabs
            data={customerTabs}
            activeTab={customerTab}
            callback={onCustomerTabChange}
            template={4}
          />
        </div>
        <div>
          {customerTab.key == "basicDetails" ? (
            <CustBasicDetails details={details} />
          ) : null}

          {customerTab.key == "addressDetails" ? (
            <CustShippingAddress addresses={details.data.shippingAddress} />
          ) : null}

          {customerTab.key == "moreDetails" ? (
            <div>
              <div className="row">
                <div className="col-6">
                  <KeyVal
                    label="IP Address"
                    labelCol="col-6"
                    contentCol="col-6"
                  >
                    : {details.data?.ipAccessDetail?.ipAddress || "--"}
                  </KeyVal>
                </div>
                <div className="col-6">
                  <KeyVal
                    label="Device Type"
                    labelCol="col-6"
                    contentCol="col-6"
                  >
                    : {details.data?.ipAccessDetail?.deviceType || "--"}
                  </KeyVal>
                </div>
                <div className="col-6">
                  <KeyVal
                    label="Last Login"
                    labelCol="col-6"
                    contentCol="col-6"
                  >
                    :{" "}
                    {details.data?.lastlogedIn ? (
                      <DateFormatter
                        date={details.data?.lastlogedIn}
                        format={"dd MMM yyyy hh:mm a"}
                      />
                    ) : (
                      "--"
                    )}
                  </KeyVal>
                </div>
                <div className="col-6">
                  <KeyVal label="Browser" labelCol="col-6" contentCol="col-6">
                    : {details.data?.ipAccessDetail?.platform?.browser || "--"}
                  </KeyVal>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </AppCard>

      <AppCard>
        <div className="border-bottom mb-3">
          <Tabs
            data={tabData}
            activeTab={activeTab}
            callback={onTabChange}
            template={4}
          />
        </div>

        {activeTab.key == "orders" ? <CustOrders cid={cid} /> : null}

        {activeTab.key == "statement" ? <CustKcStatement cid={cid} /> : null}

        {activeTab.key == "monthlySummary"
          ? // Commented out as CustMontlySales is not defined
            // <CustMontlySales cid={cid} />
            null
          : null}

        {activeTab.key == "topPurchases" ? (
          <CustTopPurchases cid={cid} fid={details.data?.franchise} />
        ) : null}
      </AppCard>

      <CustomerCallLogs
        show={callModal.show}
        customer={callModal.customer}
        callback={onCallLogsCb}
      />

      <CustomerMsgTemplateModal
        show={msgTemplateModal.show}
        customer={msgTemplateModal.customer}
        callback={onMsgTemplateCb}
        type={msgTemplateModal.type}
      />

      <CustomerUpdateModal
        show={updateModal.show}
        customerId={updateModal.customerId}
        callback={onUpdateModalCb}
      />

      <StoresModal show={showStoreModal} callback={storeModalCallback} />

      <BusyLoader show={busyloader.show} />
    </>
  );
};

export default CustomerView;
