import { CustomerService } from "@sk/services";
import { BusyLoader, PageInfo } from "@sk/uis";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import Table from "./components/Table";

const CustomerList = () => {
  const [data, setData] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [loadingTotalRecords, setLoadingTotalRecords] = useState(true);

  const paginationRef = useRef({
    totalRecords: 0,
    rowsPerPage: 15,
    activePage: 1,
    startSlNo: 1,
    endSlNo: 15,
  });

  const applyFilter = useCallback(async () => {
    loadList();
    // for total records
    setLoadingTotalRecords(true);
    const c = await getCount(getFilterParams());
    const t = c.statusCode == 200 && c.resp ? c.resp : 100;
    paginationRef.current.totalRecords = t;
    setLoadingTotalRecords(false);
  }, [loadList]);

  const init = useCallback(async () => {
    applyFilter();
  }, [applyFilter]);

  useEffect(() => {
    init();
  }, [init]);

  const loadList = useCallback(async () => {
    // for list
    setLoadingData(true);
    const r = await getData(getFilterParams());
    const d = r.resp || [];
    setData(d);
    setLoadingData(false);
  }, []);

  const getFilterParams = () => {
    let p = {
      page: paginationRef.current.activePage,
      count: paginationRef.current.rowsPerPage,
      filter: { status: "Active" },
    };
    return p;
  };

  const paginationCb = useCallback(
    (data) => {
      paginationRef.current.startSlNo = data.startSlNo;
      paginationRef.current.endSlNo = data.endSlNo;
      paginationRef.current.activePage = data.activePage;
      loadList();
    },
    [loadList]
  );

  const getData = (params) => {
    return CustomerService.getList(params);
  };

  const getCount = (params) => {
    return CustomerService.getCount(params);
  };

  return (
    <>
      <PageInfo title="Customers" />
      <Table
        data={data}
        loading={loadingData}
        paginationConfig={paginationRef.current}
        paginationCb={paginationCb}
        loadingTotalRecords={loadingTotalRecords}
      />
      <BusyLoader show={loadingData} />
    </>
  );
};

export default memo(CustomerList);
