import { CoinsDashService, PosService } from "@sk/services";
import {
  Amount,
  DateFormatter,
  DisplayUnit,
  EntitySearchInput,
  InfoPopover,
  NoDataFound,
  Pagination,
  PaginationSummary,
  TableHeader,
  TableSkeletonLoader,
  TextInput,
} from "@sk/uis";
import { set, startOfMonth, sub } from "date-fns";
import { debounce } from "lodash";
import { useCallback, useEffect, useRef, useState, useMemo } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";

const defaultPaginationConfig = {
  totalRecords: 0,
  rowsPerPage: 30,
  activePage: 1,
  startSlNo: 1,
  endSlNo: 30,
};

const tableStyle = {
  minWidth: "1800px",
};

const defaultFilterData = {
  // Define any default filter data if necessary
};

const statusOptions = PosService.getPosOrderStatusGroups().map((e) => ({
  label: e.name,
  value: e.key,
}));
statusOptions.unshift({ label: "All", value: "" });

const defaultFormData = {
  search: "",
  menu: [],
  category: [],
  brand: [],
  dateRange: [], // For date range
  status: "", // For select dropdown
};

const headers = [
  { label: "Sl No", key: "slNo" },
  { label: "Deal Name", enableSort: true, key: "dealName" },
  { label: "Deal ID", enableSort: true, key: "dealId" },
  { label: "MRP", enableSort: true, key: "mrp" },
  {
    label: "Purchase Price",
    enableSort: true,
    key: "purchasePrice",
    hasCustomTemplate: true,
    template: () => (
      <div>
        Purchase Price{" "}
        <InfoPopover content="The price at which the customer purchased the item" />
      </div>
    ),
  },
  {
    label: "Purchase Units",
    enableSort: true,
    key: "totalQuantity",
  },
  { label: "Total Amount", key: "totalAmount" },
  {
    label: "Last Purchase",
    enableSort: true,
    key: "lastPurchasedOn",
  },
  {
    label: "Stock",
    template: () => (
      <div>
        Stock in Store{" "}
        <InfoPopover content="Showing the available stock of the customer's registered store" />
      </div>
    ),
    hasCustomTemplate: true,
    key: "stock",
  },
  { label: "Menu", enableSort: true, key: "Menu" },
  { label: "Category", enableSort: true, key: "catName" },
  { label: "Brand", enableSort: true, key: "brandName" },
];

const getData = async (params, fid) => {
  const r = await CoinsDashService.getTopPurchaseCustomerList(params);
  const d = Array.isArray(r.resp) ? r.resp : [];

  const dealIds = d.map((e) => e.dealId);
  if (dealIds.length) {
    const dealsResp = await PosService.getDealsStock({
      page: 1,
      count: dealIds.length,
      filter: {
        _id: fid,
      },
      inventoryType: "Available",
      dealFilter: {
        _id: { $in: dealIds },
      },
      franchiseId: fid,
    });
    const deals = Array.isArray(dealsResp.resp) ? dealsResp.resp : [];
    d.forEach((e) => {
      const d = deals.find((x) => x._id == e.dealId);
      e._deal = d;
    });
  }

  return { data: d };
};

const getCount = async (params) => {
  let p = { ...params, outputType: "count" };
  delete p.page;
  delete p.count;
  delete p.sort;

  const r = await CoinsDashService.getTopPurchaseCustomerList(p);
  return {
    count: Array.isArray(r.resp) && r.resp.length ? r.resp[0].total : 0,
  };
};

const prepareFilterParams = (filter, pagination, sort) => {
  let p = {
    page: pagination?.activePage,
    count: pagination?.rowsPerPage,
    filter: {
      _id: filter.cid,
    },
    sort: { [sort.key]: sort.value == "asc" ? 1 : -1 },
    orderFilter: {
      "orderData.createdAt": {
        $gte: set(startOfMonth(sub(new Date(), { months: 6 })), {
          hours: 0,
          minutes: 0,
          seconds: 0,
        }),
        $lte: set(new Date(), {
          hours: 23,
          minutes: 59,
          seconds: 59,
        }),
      },
    },
  };

  const s = filter.search?.trim();

  if (s) {
    p.orderFilter = {
      $or: [
        { "orderData.deals.id": s },
        { "orderData.deals.name": { $regex: s, $options: "gi" } },
      ],
    };
  }

  p.idFilter = {};

  if (filter.menu.length) {
    p.idFilter.MenuId = filter.menu[0]?.value._id;
  }

  if (filter.category.length) {
    p.idFilter.catId = filter.category[0]?.value._id;
  }

  if (filter.brand.length) {
    p.idFilter.brandId = filter.brand[0]?.value._id;
  }

  if (!Object.keys(p.idFilter).length) {
    delete p.idFilter;
  }

  return p;
};

const CustTopPurchases = ({ cid = "", fid = "" }) => {
  const { register, getValues, control, setValue } = useForm({
    defaultValues: defaultFormData,
  });

  const [data, setData] = useState([]);

  const [records, setRecords] = useState({ loading: true, value: 0 });

  const [loadingData, setLoadingData] = useState(true);

  const filterDataRef = useRef({ ...defaultFilterData });

  const paginationRef = useRef({ ...defaultPaginationConfig });

  const sortDataRef = useRef({ key: "quantity", value: "desc" });

  const [menu] = useWatch({ control, name: ["menu"] });

  const categoryFilter = useMemo(() => {
    if (menu.length > 0) {
      return {
        filter: {
          parent: menu[0]?.value._id,
        },
      };
    } else {
      return {};
    }
  }, [menu]);

  const applyFilter = useCallback(async () => {
    paginationRef.current = { ...defaultPaginationConfig };

    filterDataRef.current = {
      ...filterDataRef.current,
      ...getValues(),
      fid,
    };

    loadList();

    setRecords({ loading: true, value: 0 });
    const r = await getCount(
      prepareFilterParams(
        filterDataRef.current,
        paginationRef.current,
        sortDataRef.current
      )
    );
    setRecords({ loading: false, value: r.count });
    paginationRef.current.totalRecords = r.count;
  }, [getValues, loadList]);

  const handleFilterClick = (type, value) => {
    if (type === "menu") {
      setValue("menu", [{ label: value.name, value }]);
      setValue("category", []);
      setValue("brand", []);
    } else if (type === "category") {
      setValue("category", [{ label: value.name, value }]);
    } else if (type === "brand") {
      setValue("brand", [{ label: value.name, value }]);
    }
    applyFilter();
  };

  const init = useCallback(async () => {
    applyFilter();
  }, [applyFilter]);

  useEffect(() => {
    filterDataRef.current = { cid: cid, fid };
    init();
  }, [cid, fid, init]);

  const loadList = useCallback(async () => {
    setLoadingData(true);

    setData([]);

    const r = await getData(
      prepareFilterParams(
        { ...filterDataRef.current, ...getValues() },
        paginationRef.current,
        sortDataRef.current
      ),
      filterDataRef.current.fid
    );

    const d = r.data || [];

    setData(d);

    setLoadingData(false);
  }, []);

  const onSearch = useCallback(
    debounce(() => {
      applyFilter();
    }, 800),
    []
  );

  const paginationCb = (data) => {
    paginationRef.current.startSlNo = data.startSlNo;
    paginationRef.current.endSlNo = data.endSlNo;
    paginationRef.current.activePage = data.activePage;
    loadList();
  };

  const sortCb = (data) => {
    sortDataRef.current = data;
    applyFilter();
  };

  const onMenuChange = (chngFn) => (e) => {
    chngFn(e);
    applyFilter();
  };

  return (
    <>
      <div className="fs-val-sm text-muted mb-3">
        Displaying purchases from the last 6 months, highlighting the top deals
        most frequently purchased by customers
      </div>
      <div className="row mb-3">
        <div className="col-3">
          <TextInput
            register={register}
            callback={onSearch}
            gap={0}
            label="Search Deal ID/Deal Name"
            name="search"
          />
        </div>
        <div className="col-3">
          <Controller
            control={control}
            name="menu"
            render={({ field }) => (
              <EntitySearchInput
                callback={onMenuChange(field.onChange)}
                value={field.value}
                gap={0}
                label="Menu"
                name="menu"
                type="menu"
              />
            )}
          />
        </div>
        <div className="col-3">
          <Controller
            control={control}
            name="category"
            render={({ field }) => (
              <EntitySearchInput
                callback={onMenuChange(field.onChange)}
                gap={0}
                label="Category"
                name="category"
                type="category"
                value={field.value}
                filterParams={categoryFilter}
              />
            )}
          />
        </div>
        <div className="col-3">
          <Controller
            control={control}
            name="brand"
            render={({ field }) => (
              <EntitySearchInput
                callback={onMenuChange(field.onChange)}
                gap={0}
                label="Brand"
                name="brand"
                type="brand"
                value={field.value}
                filter={filterDataRef.current.brand}
              />
            )}
          />
        </div>
      </div>
      <PaginationSummary
        loadingTotalRecords={records.loading}
        paginationConfig={paginationRef.current}
        className="mb-1"
      />

      <div className="tbl-scroll-container custom-scrollbar thin-scrollbar">
        <table className="table bg-white table-sm" style={tableStyle}>
          <TableHeader
            data={headers}
            noBg={true}
            sortCb={sortCb}
            sort={sortDataRef.current}
          />
          <tbody className="fs-val-md">
            {!loadingData && !data.length ? (
              <tr>
                <td colSpan={headers.length}>
                  <NoDataFound>No Data found</NoDataFound>
                </td>
              </tr>
            ) : null}

            {loadingData ? (
              <TableSkeletonLoader
                cols={headers.length}
                rows={10}
                height={20}
              />
            ) : null}

            {data.map((x, index) => (
              <tr key={x.dealId} className="fs-val-md">
                <td>{paginationRef.current.startSlNo + index}</td>
                <td>
                  <div className="mb-1 text-wrap" style={{ width: "300px" }}>
                    {x.dealName}
                  </div>
                </td>
                <td>{x.dealId}</td>
                <td>
                  <Amount value={x.mrp || 0} />
                </td>
                <td>
                  <Amount value={x.custPurchasedPrice || 0} />
                </td>
                <td>
                  {x.totalQuantity}{" "}
                  {x.sellInLooseQty
                    ? "kg"
                    : x.totalQuantity > 1
                    ? "units"
                    : "unit"}
                </td>
                <td>
                  <Amount value={x.totalAmount} />
                </td>
                <td>
                  <DateFormatter
                    date={x.lastPurchasedOn}
                    format="dd MMM yyyy"
                  />
                </td>
                <td className={!x._deal?._qty ? "text-danger" : ""}>
                  <DisplayUnit
                    isLoose={x._deal?.sellInLooseQty}
                    value={x._deal?._qty || 0}
                    noConv={true}
                  />
                </td>
                <td>
                  <button
                    className="btn btn-link p-0 text-dark text-start fs-val-md"
                    onClick={() =>
                      handleFilterClick("menu", { _id: x.menuId, name: x.Menu })
                    }
                  >
                    {x.Menu}
                  </button>
                </td>
                <td>
                  <button
                    className="btn btn-link p-0 text-dark text-start fs-val-md"
                    onClick={() =>
                      handleFilterClick("category", {
                        _id: x.catId,
                        name: x.catName,
                      })
                    }
                  >
                    {x.catName}
                  </button>
                </td>
                <td>
                  <button
                    className="btn btn-link p-0 text-dark text-start fs-val-md"
                    onClick={() =>
                      handleFilterClick("brand", {
                        _id: x.brandId,
                        name: x.brandName,
                      })
                    }
                  >
                    {x.brandName}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="text-end">
        <Pagination
          callback={paginationCb}
          activePage={paginationRef.current.activePage}
          rowsPerPage={paginationRef.current.rowsPerPage}
          totalRecords={paginationRef.current.totalRecords}
        />
      </div>
    </>
  );
};

export default CustTopPurchases;
