import {
  DateFormatter,
  HighlightText,
  NoDataFound,
  PaginationBlock,
  PaginationSummary,
  TableHeader,
} from "@sk/uis";

// Define the new headers for the Franchise Table
const headers = [
  { label: "Sl No", width: "5%" },
  { label: "ID" },
  { label: "Name" },
  { label: "Type" },
  { label: "Sub Type" },
  { label: "State" },
  { label: "District" },
  { label: "Town" },
  { label: "Created At" },
  { label: "Status" },
  { label: "Actions" },
];

const containerStyle = {
  maxHeight: "600px",
  overflowY: "auto",
};

const tableStyle = {
  minWidth: "1000px",
};

const FranchiseTable = ({
  data,
  loading,
  paginationConfig,
  paginationCb,
  loadingTotalRecords,
}) => {
  return (
    <>
      <PaginationSummary
        loadingTotalRecords={loadingTotalRecords}
        paginationConfig={paginationConfig}
        className="mb-3"
      />
      <div
        className="tbl-scroll-container custom-scrollbar thin-scrollbar mb-3"
        style={containerStyle}
      >
        <table
          className="table table-sm bg-white table-hover"
          style={tableStyle}
        >
          <TableHeader data={headers} isSticky={true} />
          <tbody>
            {!loading && !data.length ? (
              <tr>
                <td colSpan={headers.length}>
                  <NoDataFound />
                </td>
              </tr>
            ) : null}
            {data.map((x, index) => (
              <tr key={x._id}>
                <td>{paginationConfig.startSlNo + index}</td>
                <td>{x._id}</td>
                <td>
                  <button
                    type="button"
                    className="btn btn-link fs-val-sm text-dark text-start p-0"
                  >
                    {x.name}
                  </button>
                </td>
                <td>
                  <HighlightText type="success" template={2}>
                    {x.sk_franchise_details?.franchise_type}
                  </HighlightText>
                </td>
                <td>
                  <HighlightText type="warning" template={2}>
                    {x.sk_franchise_details?.franchise_sub_type}
                  </HighlightText>
                </td>
                <td>{x.state}</td>
                <td>{x.district}</td>
                <td>{x.town}</td>
                <td>
                  <DateFormatter date={x.createdAt} />
                </td>
                <td>
                  <HighlightText type={x.statusType} template={2}>
                    {x.status}
                  </HighlightText>
                </td>
                <td>
                  <button
                    type="button"
                    className="btn btn-sm btn-outline-primary fs-val-sm me-2"
                  >
                    View
                  </button>
                  <button
                    type="button"
                    className="btn btn-sm btn-outline-primary fs-val-sm"
                  >
                    Edit
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <PaginationBlock
        paginationConfig={paginationConfig}
        paginationCb={paginationCb}
        loadingTotalRecords={loadingTotalRecords}
        size="sm"
      />
    </>
  );
};

export default FranchiseTable;
