import { useFetchUrlQueryString } from "@sk/hooks";
import { CoinsDashService, NavService } from "@sk/services";
import {
  Amount,
  AppCard,
  DateFormatter,
  NoDataFound,
  PageLoader,
  Spinner,
  TableHeader,
  Tabs,
  TextInput,
  PageInfo,
  BtnLink,
} from "@sk/uis";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import ClubMembers from "./components/ClubMembers";
import ClubOrders from "./components/ClubOrders";

const clubImgStyle = { height: "28px" };

const tabData = [
  {
    tabName: "Members",
    key: "members",
  },
  {
    tabName: "Orders",
    key: "orders",
  },
  // { tabName: "Coin Statement", key: "coinStatement" },
];

const breadcrumbs = [
  {
    name: "Home",
    link: "/",
  },
  {
    name: "Club Details",
    link: "",
  },
];

const coinStatementTableHeaders = [
  { key: "member", label: "Member" },
  { key: "transaction", label: "Transaction" },
  { key: "credit", label: "Credit" },
  { key: "debit", label: "Debit" },
  { key: "date", label: "Date" },
];

const coinStatementData = [
  {
    member: "Aarav Sharma",
    transaction: "Purchase Reward",
    credit: 500,
    debit: 0,
    date: "2024-09-01",
  },
  {
    member: "Meera Iyer",
    transaction: "Referral Bonus",
    credit: 200,
    debit: 0,
    date: "2024-09-03",
  },
  {
    member: "Rohan Gupta",
    transaction: "Coin Redemption",
    credit: 0,
    debit: 300,
    date: "2024-09-05",
  },
  {
    member: "Sanya Mehta",
    transaction: "Birthday Bonus",
    credit: 100,
    debit: 0,
    date: "2024-09-07",
  },
  {
    member: "Aditya Kumar",
    transaction: "Purchase Reward",
    credit: 750,
    debit: 0,
    date: "2024-09-10",
  },
  // Add more coin statement entries as needed
];

const statCards = [
  {
    title: "Total Members",
    icon: "bi-people-fill",
    color: "primary",
    getValue: (data) => data.members.count,
    loading: (data) => data.members.loading,
  },
  {
    title: "Members Coins Earned / Redeemed",
    icon: "bi-coin",
    color: "success",
    getValue: (data) => (
      <div className="d-flex gap-2">
        <span className="text-success">{data.coinsStats.totalEarned}</span>
        <span className="text-muted">/</span>
        <span className="text-danger">{data.coinsStats.totalRedeemed}</span>
      </div>
    ),
    loading: (data) => data.coinsStats.loading,
  },
  {
    title: "Owner Coins",
    icon: "bi-coin",
    color: "warning",
    getValue: (data) => data.coinsStats.ownerEarned,
    loading: (data) => data.coinsStats.loading,
  },
  {
    title: "Orders Count / Value",
    icon: "bi-bag-fill",
    color: "info",
    getValue: (data) => (
      <div className="d-flex gap-2">
        <span>{data.orders.count}</span>
        <span className="text-muted">/</span>
        <span>
          <Amount value={data.orders.value || 0} />
        </span>
      </div>
    ),
    loading: (data) => data.orders.loading,
  },
];

const ClubDetail = () => {
  const navigate = useNavigate();

  const { register } = useForm();

  const [searchParams] = useSearchParams();
  const query = useFetchUrlQueryString(searchParams);
  const cid = query.id;

  const [loading, setLoading] = useState(true);

  const [details, setDetails] = useState({});

  const [members, setMembers] = useState({
    loading: true,
    count: 0,
  });

  const [orders, setOrders] = useState({
    loading: true,
    count: 0,
    value: 0,
  });

  const [coinsStats, setCoinsStats] = useState({
    loading: true,
    totalEarned: 0,
    totalRedeemed: 0,
    ownerEarned: 0,
  });

  const [activeTab, setActiveTab] = useState(tabData[0]);

  const init = useCallback(async () => {
    setLoading(true);

    const r1 = await CoinsDashService.getClubWise({
      filter: { customerId: cid },
    });

    const d = Array.isArray(r1.resp) && r1.resp.length > 0 ? r1.resp[0] : {};

    const r2 = await CoinsDashService.getClubMembersSummary({
      filter: { senderRefId: cid },
    });

    if (Array.isArray(r2.resp) && r2.resp.length > 0) {
      const o = r2.resp[0];
      setMembers({ loading: false, count: o.childMemberCount || 0 });
      setCoinsStats({
        loading: false,
        totalEarned: o.totalCoinsRewarded || 0,
        totalRedeemed: o.totalCoinsRedeemed || 0,
        ownerEarned: o.totalParentCoins || 0,
      });
      setOrders({
        loading: false,
        count: o.orderCount || 0,
        value: o.orderValue || 0,
      });
    } else {
      setMembers({ loading: false, count: 0 });
      setCoinsStats({
        loading: false,
        totalEarned: 0,
        totalRedeemed: 0,
        ownerEarned: 0,
      });
      setOrders({
        loading: false,
        count: 0,
        value: 0,
      });
    }

    setDetails({
      clubName: d.clubName,
      fName: d.clubOwnerName,
      lName: "",
      createdAt: d.clubCreatedOn,
      _id: d.clubOwnerId,
    });

    setLoading(false);
  }, [cid]);

  useEffect(() => {
    if (cid) {
      setActiveTab(tabData[0]);
      init();
    }
  }, [init, cid]);

  const onTabChange = (tab) => {
    setActiveTab(tab.value);
  };

  const memberCb = useCallback((e) => {}, []);

  const viewCustomer = () => {
    NavService.openInNewTab("/users/customer/view/", {
      id: details._id,
    });
  };

  const renderStatsGrid = () => (
    <div className="row g-2">
      {statCards.map((card, index) => (
        <div className="col" key={index}>
          <div className={`bg-${card.color} bg-opacity-10 rounded-3 p-2`}>
            <div className="d-flex align-items-center">
              <div
                className={`rounded-circle bg-${card.color} d-flex align-items-center justify-content-center me-2`}
                style={{
                  width: "40px",
                  height: "40px",
                  color: "white",
                }}
              >
                <i className={`bi ${card.icon}`}></i>
              </div>
              <div>
                <div className="text-muted" style={{ lineHeight: "1.1" }}>
                  <small>{card.title}</small>
                </div>
                <div
                  className={`fw-bold text-${card.color}`}
                  style={{ fontSize: "1.25rem", marginTop: "2px" }}
                >
                  {card.loading({ members, coinsStats, orders }) ? (
                    <Spinner isSmall={true} />
                  ) : (
                    card.getValue({ members, coinsStats, orders })
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );

  return (
    <>
      <PageInfo
        title="Club Details"
        breadcrumbs={breadcrumbs}
        navigate={navigate}
      />
      {loading ? (
        <PageLoader />
      ) : !details?._id ? (
        <NoDataFound>No data to display</NoDataFound>
      ) : (
        <>
          <AppCard className="mb-3">
            <div className="mb-3">
              <div className="d-flex align-items-center justify-content-between mb-2">
                <h4 className="mb-0">{details?.clubName}</h4>
                <span className="badge bg-primary">Club</span>
              </div>
              <div className="d-flex align-items-center text-muted mb-3 fs-val-md">
                <i className="bi bi-person me-2"></i>
                <span className="me-3">
                  <span className="align-middle">Owner: </span>
                  <BtnLink className="fs-val-md" onClick={viewCustomer}>
                    <strong>{details.fName}</strong>
                  </BtnLink>
                </span>
                <i className="bi bi-calendar3 me-2"></i>
                <span>
                  Created:{" "}
                  <strong className="fs-val-sm">
                    <DateFormatter
                      date={details.createdAt}
                      format="dd MMM yyyy"
                    />
                  </strong>
                </span>
              </div>
              <hr className="my-3" />
              {renderStatsGrid()}
            </div>
          </AppCard>

          <AppCard>
            <div className="border-bottom mb-3">
              <Tabs
                data={tabData}
                activeTab={activeTab}
                callback={onTabChange}
                template={4}
              />
            </div>

            {activeTab.key == "members" ? (
              <ClubMembers callback={memberCb} cid={cid} />
            ) : null}

            {activeTab.key == "orders" ? (
              <ClubOrders
                callback={memberCb}
                cid={cid}
                startDate={details.createdAt}
              />
            ) : null}

            {activeTab.key === "coinStatement" && (
              <>
                <div className="row mb-3">
                  <div className="col-6">
                    <TextInput
                      name="searchCoinStatement"
                      register={register}
                      placeholder="Search coin statement..."
                    />
                  </div>
                </div>
                <table className="table">
                  <TableHeader data={coinStatementTableHeaders} />
                  <tbody className="fs-val-md">
                    {coinStatementData.map((entry, index) => (
                      <tr key={index}>
                        <td>{entry.member}</td>
                        <td>{entry.transaction}</td>
                        <td className="text-success">
                          {entry.credit > 0 && <Amount value={entry.credit} />}
                        </td>
                        <td className="text-danger">
                          {entry.debit > 0 && <Amount value={entry.debit} />}
                        </td>
                        <td>{entry.date}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            )}
          </AppCard>
        </>
      )}
    </>
  );
};

export default ClubDetail;
