import { Amount, DateFormatter, KeyVal } from "@sk/uis"; // Assuming these components are available

const CustBasicDetails = ({ details }) => {
  return (
    <div>
      <div className="fs-val-md fw-semibold mb-2">Basic Info</div>
      <div className="row mb-2">
        <div className="col-4">
          <KeyVal
            label={
              <>
                <i className="bi bi-calendar"></i> Age
              </>
            }
            labelCol="col-5"
            contentCol="col-7"
          >
            : {details.data?.age || "--"}
          </KeyVal>
        </div>
        <div className="col-4">
          <KeyVal
            label={
              <>
                <i className="bi bi-gender-ambiguous"></i> Gender
              </>
            }
            labelCol="col-5"
            contentCol="col-7"
          >
            : {details.data?.gender || "--"}
          </KeyVal>
        </div>
        <div className="col-4">
          <KeyVal
            label={
              <>
                <i className="bi bi-calendar-date"></i> Date of Birth
              </>
            }
            labelCol="col-5"
            contentCol="col-7"
          >
            :{" "}
            {details.data?.dob ? (
              <DateFormatter date={details.data.dob} format="dd MMM yyyy" />
            ) : (
              "--"
            )}
          </KeyVal>
        </div>
        <div className="col-5">
          <KeyVal
            label={
              <>
                <i className="bi bi-envelope"></i> Email
              </>
            }
            labelCol="col-4"
            contentCol="col-8"
          >
            : {details.data?.email || "--"}
          </KeyVal>
        </div>
      </div>

      <div className="fs-val-md fw-semibold mb-2">Additional Info</div>
      <div className="row">
        <div className="col-4">
          <KeyVal
            label={
              <>
                <i className="bi bi-heart"></i> Marital Status
              </>
            }
            labelCol="col-5"
            contentCol="col-7"
          >
            : {details.data?.maritalStatus || "--"}
          </KeyVal>
        </div>
        <div className="col-4">
          <KeyVal
            label={
              <>
                <i className="bi bi-mortarboard"></i> Qualification
              </>
            }
            labelCol="col-5"
            contentCol="col-7"
          >
            : {details.data?.eductioanQualification || "--"}
          </KeyVal>
        </div>
        <div className="col-4">
          <KeyVal
            label={
              <>
                <i className="bi bi-briefcase"></i> Profession
              </>
            }
            labelCol="col-5"
            contentCol="col-7"
          >
            : {details.data?.kycDetails?.profession || "--"}
          </KeyVal>
        </div>
      </div>
      <div className="row">
        <div className="col-4">
          <KeyVal
            label={
              <>
                <i className="bi bi-people"></i> Family Members
              </>
            }
            labelCol="col-5"
            contentCol="col-7"
          >
            : {details.data?.generalInformation?.numberOfFamilyMembers || "--"}
          </KeyVal>
        </div>
        <div className="col-4">
          <KeyVal
            label={
              <>
                <i className="bi bi-cart"></i> Shopping Expense
              </>
            }
            labelCol="col-5"
            contentCol="col-7"
          >
            : <Amount value={details.data?.monthlyShoppingExpenses || 0} />{" "}
            <span className="fs-val-xs text-muted">monthly</span>
          </KeyVal>
        </div>
      </div>
    </div>
  );
};

export default CustBasicDetails;
