import { CoinsDashService, NavService } from "@sk/services";
import {
  DateFormatter,
  EntitySearchInput,
  HighlightText,
  NoDataFound,
  Pagination,
  PaginationSummary,
  SelectInput,
  TableHeader,
  TableSkeletonLoader,
} from "@sk/uis";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";

const tableStyle = { minWidth: "1300px" };

const defaultPaginationConfig = {
  totalRecords: 0,
  rowsPerPage: 50,
  activePage: 1,
  startSlNo: 1,
  endSlNo: 50,
};

const headers = [
  { label: "Sl No", key: "slNo", width: "60px" },
  {
    label: "Member Details",
    key: "customerName",
    width: "180px",
    enableSort: true,
  },
  {
    label: "Member ID",
    key: "reciverRefId",
    width: "120px",
  },
  {
    label: "District",
    key: "customerDistrict",
    width: "120px",
    enableSort: true,
  },
  { label: "Status", key: "status", enableSort: true, width: "100px" },
  {
    label: "Registered On",
    key: "registeredOn",
    width: "140px",
    enableSort: true,
  },
  {
    label: "Last Ordered Date",
    key: "lastOrderDate",
    enableSort: true,
    width: "140px",
  },
  { label: "Orders", key: "orderCount", enableSort: true, width: "80px" },
  {
    label: "Owner Earned",
    key: "totalParentCoins",
    enableSort: true,
    width: "120px",
  },
  {
    label: "Member Earned",
    key: "totalCoinsRewarded",
    enableSort: true,
    width: "160px",
  },
  {
    label: "Member Redeemed",
    key: "totalCoinsRedeemed",
    enableSort: true,
    width: "160px",
  },
];

const statusOptions = [
  { value: "", label: "All Members" },
  { value: "Active", label: "Active" },
  { value: "Inactive", label: "Inactive" },
];

const getData = async (params) => {
  const r = await CoinsDashService.getClubMembersList(params);
  return { data: Array.isArray(r.resp) ? r.resp : [] };
};

const getCount = async (params) => {
  const p = { ...params, outputType: "count" };

  delete p.page;
  delete p.count;

  const r = await CoinsDashService.getClubMembersList(p);

  return {
    count: Array.isArray(r.resp) && r.resp.length > 0 ? r.resp[0].total : 0,
  };
};

const prepareFilterParams = (filter, pagination, sort) => {
  let p = {
    page: pagination?.activePage,
    count: pagination?.rowsPerPage,
    filter: {
      senderRefId: filter.cid,
    },
    matchFilter: {},
    projectFilter: {},
  };

  if (filter.search?.length > 0) {
    p.filter.reciverRefId = filter.search[0].value?._id;
  }

  if (filter.status == "Inactive") {
    // p.filter["customerStatus"] = ["Inactive"];
    // p.matchFilter["custData.lastOrderDate"] = {
    //   $lte: sub(new Date(), { days: 30 }).toISOString(),
    // };
    p.projectFilter["status"] = "Inactive";
  }

  if (filter.status == "Active") {
    p.projectFilter["status"] = "Active";
    // p.matchFilter["custData.lastOrderDate"] = {
    //   $gte: sub(new Date(), { days: 30 }).toISOString(),
    // };
  }

  if (sort?.key) {
    p.sort = { [sort.key]: sort.value == "asc" ? 1 : -1 };
  }

  if (!Object.keys(p.filter).length) {
    delete p.filter;
  }

  return p;
};

const ClubMembers = ({ cid, callback }) => {
  const { register, getValues, control } = useForm();

  const [data, setData] = useState([]); // Added state for data
  const [loadingData, setLoadingData] = useState(true); // Added loading state
  const [records, setRecords] = useState({ loading: true, value: 0 }); // Added records state
  const [activeCount, setActiveCount] = useState({ loading: true, value: 0 });
  const [inactiveCount, setInactiveCount] = useState({
    loading: true,
    value: 0,
  });

  const filterParams = useMemo(
    () => ({
      filter: { "referralInfo.referrerInfo.id": cid },
    }),
    [cid]
  );

  const filterDataRef = useRef({}); // Updated to use ref for filter data
  const paginationRef = useRef({ ...defaultPaginationConfig }); // Added pagination ref
  const sortRef = useRef({});

  const applyFilter = useCallback(async () => {
    paginationRef.current = { ...defaultPaginationConfig };

    setLoadingData(true); // Set loading state

    filterDataRef.current = {
      ...filterDataRef.current,
      ...getValues(), // Assuming getValues() includes search and status
    };

    loadSummary();

    const p = prepareFilterParams(
      filterDataRef.current,
      paginationRef.current,
      sortRef.current
    );

    const r = await getCount(p); // Get count
    setRecords({ loading: false, value: r.count }); // Update records state
    paginationRef.current.totalRecords = r.count; // Update pagination total records
    loadList(); // Load the list
  }, [getValues, loadList]);

  const loadList = useCallback(async () => {
    const p = prepareFilterParams(
      filterDataRef.current,
      paginationRef.current,
      sortRef.current
    );

    setLoadingData(true);
    setData([]);
    const r = await getData(p);
    setData(r.data); // Set data state
    setLoadingData(false); // Reset loading state
  }, []);

  useEffect(() => {
    if (cid) {
      filterDataRef.current.cid = cid;
      applyFilter(); // Call applyFilter on mount
    }
  }, [applyFilter, cid]);

  const paginationCb = (data) => {
    paginationRef.current.startSlNo = data.startSlNo; // Update starting serial number
    paginationRef.current.endSlNo = data.endSlNo; // Update ending serial number
    paginationRef.current.activePage = data.activePage; // Update active page
    loadList(); // Load the list with updated pagination
  };

  const onSearch = (change) => (val) => {
    change(val);
    applyFilter();
  };

  const onStatusChange = () => {
    applyFilter();
  };

  const handleMemberClick = (id) => {
    NavService.openInNewTab("/users/customer/view", {
      id,
    });
  };

  const loadSummary = async () => {
    setActiveCount({ loading: true, value: 0 });
    setInactiveCount({ loading: true, value: 0 });

    const activeFilter = prepareFilterParams({
      ...filterDataRef.current,
      status: "Active",
    });
    const inactiveFilter = prepareFilterParams({
      ...filterDataRef.current,
      status: "Inactive",
    });

    const activeCountResponse = await getCount(activeFilter);
    const inactiveCountResponse = await getCount(inactiveFilter);

    setActiveCount({ loading: false, value: activeCountResponse.count });
    setInactiveCount({ loading: false, value: inactiveCountResponse.count });
  };

  const sortCb = (data) => {
    sortRef.current = data;
    paginationRef.current.activePage = 1;
    applyFilter();
  };

  return (
    <>
      {/* active and inactive members count */}
      <div className="row mb-2">
        <div className="col-auto">
          <span className="badge bg-success">
            Active Members:{" "}
            <strong>{activeCount.loading ? "..." : activeCount.value}</strong>
          </span>
        </div>
        <div className="col-auto">
          <span className="badge bg-danger">
            Inactive Members:{" "}
            <strong>
              {inactiveCount.loading ? "..." : inactiveCount.value}
            </strong>
          </span>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-6">
          <Controller
            control={control}
            name="search"
            render={({ field }) => (
              <EntitySearchInput
                type="customer"
                label="Search by Name"
                gap={0}
                callback={onSearch(field.onChange)} // Assuming onSearch is defined to handle input changes
                value={field.value}
                filterParams={filterParams}
                uid={"srch-mem-" + cid}
              />
            )}
          />
        </div>
        <div className="col-6">
          <SelectInput
            label="Member Status"
            options={statusOptions}
            register={register}
            name="status" // Ensure this matches your form data
            callback={onStatusChange} // Assuming onStatusChange is defined to handle dropdown changes
            gap={0}
          />
        </div>
      </div>
      <PaginationSummary
        paginationConfig={paginationRef.current}
        loadingTotalRecords={records.loading}
        fwSize="md"
        className="mb-2"
      />
      <div className="tbl-scroll-cnt custom-scrollbar mb-2">
        <table
          className="table table-hover table-sm table-striped"
          style={tableStyle}
        >
          <TableHeader
            data={headers}
            sortCb={sortCb}
            sort={sortRef.current}
            noBg={true}
          />
          <tbody className="fs-val-md">
            {loadingData ? (
              <TableSkeletonLoader
                cols={headers.length}
                rows={10}
                height={40}
              />
            ) : null}

            {!loadingData && !data.length ? (
              <tr>
                <td colSpan={headers.length}>
                  <NoDataFound>No Data found</NoDataFound>
                </td>
              </tr>
            ) : null}

            {data.map((e, index) => (
              <tr key={e.reciverRefId + ":" + index}>
                <td>{paginationRef.current.startSlNo + index}</td>
                <td>
                  <button
                    className="btn btn-link p-0 fs-val-md text-dark text-start"
                    onClick={() => handleMemberClick(e.reciverRefId)}
                  >
                    {e.customerName}
                  </button>
                </td>
                <td>{e.reciverRefId}</td>
                <td>{e.customerDistrict}</td>
                <td>
                  <HighlightText
                    template={2}
                    type={e.status === "Active" ? "success" : "danger"}
                  >
                    {e.status}
                  </HighlightText>
                </td>
                <td>
                  {e.registeredOn ? (
                    <DateFormatter date={e.registeredOn} format="dd MMM yyyy" />
                  ) : (
                    <span>--</span>
                  )}
                </td>
                <td>
                  {e.lastOrderDate ? (
                    <DateFormatter
                      date={e.lastOrderDate}
                      format="dd MMM yyyy"
                    />
                  ) : (
                    <span>--</span>
                  )}
                </td>
                <td>{e.orderCount || 0}</td>
                <td className="text-success">
                  {e.totalParentCoins || 0} coins
                </td>
                <td className="text-danger">
                  {e.totalCoinsRewarded || 0} coins
                </td>
                <td className="text-warning">
                  {e.totalCoinsRedeemed || 0} coins
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="text-end">
        <Pagination
          callback={paginationCb}
          activePage={paginationRef.current.activePage}
          rowsPerPage={paginationRef.current.rowsPerPage}
          totalRecords={paginationRef.current.totalRecords}
        />
      </div>
    </>
  );
};

export default ClubMembers;
