import { NoDataFound, PaginationBlock, TableHeader } from "@sk/uis";
import { memo } from "react";

const headers = [
  { label: "Sl No", width: "5%" },
  { label: "First Name" },
  { label: "Last Name" },
  { label: "Mobile Number" },
];

const Table = ({
  data,
  loading,
  paginationConfig,
  paginationCb,
  loadingTotalRecords,
}) => {
  return (
    <>
      <table className="table table-bordered bg-white">
        <TableHeader data={headers} />
        <tbody>
          {!loading && !data.length ? (
            <tr>
              <td colSpan={headers.length}>
                <NoDataFound />
              </td>
            </tr>
          ) : null}
          {data.map((x, index) => (
            <tr key={x._id}>
              <td>{paginationConfig.startSlNo + index}</td>
              <td>{x.fName}</td>
              <td>{x.lName}</td>
              <td>{x.mobile}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <PaginationBlock
        paginationConfig={paginationConfig}
        paginationCb={paginationCb}
        loadingTotalRecords={loadingTotalRecords}
      />
    </>
  );
};

export default memo(Table);
