import { FranchiseService } from "@sk/services";
import { AppCard, BusyLoader, PageInfo } from "@sk/uis";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import FranchiseTable from "./components/FranchiseTable";

const FranchiseList = () => {
  const [data, setData] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [loadingTotalRecords, setLoadingTotalRecords] = useState(true);

  const filterRef = useRef({});

  const paginationRef = useRef({
    totalRecords: 0,
    rowsPerPage: 50,
    activePage: 1,
    startSlNo: 1,
    endSlNo: 50,
  });

  const applyFilter = useCallback(async () => {
    loadList();
    // for total records
    setLoadingTotalRecords(true);
    const c = await getCount(getFilterParams());
    const t = c.statusCode == 200 && c.resp ? c.resp : 100;
    paginationRef.current.totalRecords = t;
    setLoadingTotalRecords(false);
  }, [loadList]);

  const init = useCallback(async () => {
    applyFilter();
  }, [applyFilter]);

  useEffect(() => {
    init();
  }, [init]);

  const loadList = useCallback(async () => {
    // for list
    setLoadingData(true);
    const r = await getData(
      getFilterParams(filterRef.current, paginationRef.current)
    );
    const d = r.resp || [];
    setData(d);
    setLoadingData(false);
  }, []);

  const paginationCb = useCallback(
    (data) => {
      paginationRef.current.startSlNo = data.startSlNo;
      paginationRef.current.endSlNo = data.endSlNo;
      paginationRef.current.activePage = data.activePage;
      loadList();
    },
    [loadList]
  );

  return (
    <>
      <div className="my-3 d-flex justify-content-between align-items-center">
        <PageInfo
          title="Franchises"
          breadcrumbs={breadcrumbs}
          noMargin={true}
        />
        <div>
          <button className="btn btn-primary">
            <i className="bi bi-plus-circle me-2"></i>
            Add Franchise
          </button>
        </div>
      </div>
      <AppCard>
        <FranchiseTable
          data={data}
          loading={loadingData}
          paginationConfig={paginationRef.current}
          paginationCb={paginationCb}
          loadingTotalRecords={loadingTotalRecords}
        />
      </AppCard>
      <BusyLoader show={loadingData} />
    </>
  );
};

const breadcrumbs = [
  { name: "Home", link: "/auth/init" },
  { name: "Franchises" },
];

const getData = (params) => {
  return FranchiseService.getList(params);
};

const getCount = (params) => {
  return FranchiseService.getCount(params);
};

const getFilterParams = (filter, pagination) => {
  let p = {
    page: pagination?.activePage,
    count: pagination?.rowsPerPage,
    filter: {},
  };
  return p;
};

export default memo(FranchiseList);
